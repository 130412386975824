<template>
  <GModal name="modal" class="modal modal-cancel-shopping">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">{{ user == null ? 'Batalkan Pesanan?' : 'Keluar' }}</div>
        <div class="text">
          <p>
            Sedikit lagi pesananmu selesai, Bestie!<br />{{
              user == null
                ? 'Kamu harus mengulang dari awal jika pesanan dibatalkan.'
                : 'Tetap keluar & membatalkan pesanan?'
            }}
          </p>
        </div>
      </div>
    </div>
    <div slot="body">
      <div class="logout" @click="$emit('logout')">
        <span>Ya, keluar</span>
      </div>
      <div v-if="cart_qty_total" class="continue-payment" @click="$emit('close')">
        <span>{{ user == null ? 'Lanjutkan Belanja' : 'Batalkan' }}</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalCancelShopping',

  computed: {
    user() {
      return this.$store.state.QRcode.user;
    },
    cart_qty_total() {
      return this.$store.state.vmCola.cart_qty;
    },
  },
};
</script>
